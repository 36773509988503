import React from "react";

import { Grid, Stack, Typography, Alert } from "../../index";
import { styled } from "@mui/material/styles";
import { IUploadInput } from "../../../../interface/InputField";
import LinearProgress, {
    LinearProgressProps,
} from "@mui/material/LinearProgress";

import { ReactComponent as AddDocumentIcon } from "../../../../assets/new-document.svg";
import { ReactComponent as FileIcon } from "../../../../assets/file.svg";
import { ReactComponent as CloseIcon } from "../../../../assets/xmark-solid.svg";
import { ReactComponent as ExcelIcon } from "../../../../assets/excel.svg";
import { ReactComponent as FileLinesIcon } from "../../../../assets/file-lines.svg";
import { ReactComponent as ImageIcon } from "../../../../assets/image.svg";
import { useCommonContext } from "@betagro/ui-common";
import useTrans from "../../../../hooks/useTranslation";
const FormLabel = styled("label")(() => ({
    fontSize: "1.25rem",
    fontWeight: "400",
    display: "flex",
    alignItems: "center",
    color: "#464B55",
}));
const dragStyle = {
    backgroundColor: "#ffffff",
};

const styleIcon = {
    marginBottom: "0.5rem",
};

const UploadedContainer = styled("div")(() => ({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: "0.5rem 1.25rem",
    height: "4rem",
    maxWidth: "100%",
    textAlign: "center",
    background: "rgb(237, 247, 237);",
    borderRadius: "0.75rem",
    marginTop: "2rem",
    marginBottom: "2rem",
    justifyContent: "space-between",
}));

const ProgressContainer = styled("div")(() => ({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: "0.5rem 1.25rem",
    height: "4rem",
    maxWidth: "100%",
    textAlign: "center",
    background:
        "linear-gradient(0deg, rgba(255, 255, 255, 0.95), rgba(255, 255, 255, 0.95)), #6C6D71",
    borderRadius: "0.75rem",
    marginTop: "2rem",
    marginBottom: "2rem",
    justifyContent: "space-between",
}));

const ProgressBar = styled(LinearProgress)(() => ({
    width: "100%",
    "& span:nth-of-type(1)": {
        background: "#84BD00",
    },
}));

const ProgressCount = styled(Typography)(() => ({
    minWidth: "2.188rem",
}));

const Input = styled("input")(() => ({
    display: "none",
}));

const TitleLabel = styled("p")(() => ({
    fontSize: "1.5rem",
    fontWeight: "600",
}));

const Label = styled("label")((props: any) => ({
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderWidth: "2px",
    borderRadius: "1rem",
    borderStyle: "dashed",
    borderColor: props["aria-errormessage"] ? "red" : "#cbd5e1",
    backgroundColor: "#f8fafc",
}));

const UploadContainer = styled("div")(() => ({
    height: "10rem",
    maxWidth: "100%",
    textAlign: "center",
    position: "relative",
}));

const DragContainer = styled("div")(() => ({
    position: "absolute",
    width: "100%",
    height: "100%",
    borderRadius: "1rem",
    top: "0px",
    right: "0px",
    bottom: "0px",
    left: "0px",
}));

const TempFileLink = styled("a")(() => ({
    backgroundColor: "initial",
    color: "#007bff",
    textDecoration: "none",
}));

const Mask = styled("div")(() => ({
    fontSize: "1rem",
    color: "red",
}));

const UploadButton = styled("div")(() => ({
    cursor: "pointer",
    fontWeight: "500",
    fontSize: "1.125rem",
    lineHeight: "1.375rem",
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    color: "#84BD00",
    borderBottom: "2px solid #84BD00",
    width: "fit-content",
    marginLeft: "auto",
    marginRight: "auto",
}));
const ViewFiles = styled("span")(() => ({
    color: "var(--green-green, #84BD00)",
    fontFamily: "DB Helvethaica X",
    fontSize: "1.25rem",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "1.5rem",
}));

const Image = styled("img")(() => ({
    height: "8rem",
    borderRadius: "1rem",
    width: "100%",
    marginTop: "2rem",
}));

const RemoveImage = styled("div")(() => ({
    position: "absolute",
    top: "50px",
    right: "8px",
    color: "var(--gray-g-70, #47474A)",
}));

// function LinearProgressWithLabel(
//     props: LinearProgressProps & { value: number }
// ) {
//     return (
//         <>
//             <ProgressBar variant="determinate" color="inherit" {...props} />
//             <ProgressCount variant="body2">{`${Math.round(
//                 props.value
//             )}%`}</ProgressCount>
//         </>
//     )
// }

const UploadInput = ({
    xs,
    md,
    label = "",
    files,
    id,
    error,
    success,
    accept,
    placeholder,
    uploadedFiles,
    handleChange,
    handleClose,
    multiple,
    tempName,
    tempFile,
    isCustomer = true,
    removeUploadedFiles,
    maskText = "",
    isView = undefined,
    viewDocType,
}: IUploadInput) => {
    // drag state
    const [dragActive, setDragActive] = React.useState(false);
    const [progress, setProgress] = React.useState(0);
    const { userContextInfo } = useCommonContext();

    const [message] = useTrans();

    // ref
    const inputRef = React.useRef(null);

    React.useEffect(() => {
        //  const timer = setInterval(() => {
        //   setProgress((prev) => ({
        //     ...prev,
        //     fileName: prev.fileName === 100 ? 0 : prev.fileName + 10
        //   }))
        // }, 800);
        // return () => {
        //   clearInterval(timer);
        // }
    }, []);

    React.useEffect(() => {
        if (files?.length === 0) {
            inputRef.current.value = null;
        }
    }, [files]);

    // handle drag events
    const handleDrag = function (e) {
        e.preventDefault();
        e.stopPropagation();
        if (e.type === "dragenter" || e.type === "dragover") {
            setDragActive(true);
        } else if (e.type === "dragleave") {
            setDragActive(false);
        }
    };

    // triggers when file is dropped
    const handleDrop = function (e) {
        e.preventDefault();
        e.stopPropagation();
        setDragActive(false);

        if (e.dataTransfer.files && e.dataTransfer.files[0]) {
            handleChange(e.dataTransfer.files);
        }
    };

    // triggers when file is selected with click
    const handleFileChange = function (e) {
        e.preventDefault();
        if (e.target.files && e.target.files[0]) {
            setProgress(100);
            handleChange(e.target.files);
        }
    };

    // triggers the input when the button is clicked
    const onButtonClick = (e) => {
        e.preventDefault();
        if (isCustomer) {
            inputRef.current.click();
        }
    };

    const handleCloseSelecetd = () => {
        inputRef.current.value = null;
        handleClose();
    };

    const gridLabelStyle = {
        display: "flex",
        alignItems: "center",
        marginBottom: "3rem",
        // justifyContent: "end",
    };
    const labelStyle = {
        color: "#919295",
        fontSize: "1.25rem",
        fontFamily: "DB Helvethaica X 65 Regular",
        height: "1.5rem",
    };

    const textStyle = {
        color: "#000000",
    };

    return (
        <>
            {isView && (
                <>
                    <Grid item md={3} sx={gridLabelStyle}>
                        <FormLabel
                            style={
                                isView != undefined
                                    ? { ...labelStyle, ...textStyle }
                                    : labelStyle
                            }
                        >
                            {message(label)}
                        </FormLabel>
                    </Grid>
                    <Grid item xs={9} md={9}>
                        <span>
                            {uploadedFiles && uploadedFiles.length
                                ? uploadedFiles.map((val, index) => {
                                      return (
                                          <>
                                              <ViewFiles>
                                                  {" "}
                                                  {"\u00A0"}
                                                  {viewDocType === "file" ? (
                                                      <FileLinesIcon />
                                                  ) : (
                                                      <ImageIcon />
                                                  )}
                                                  {"\u00A0"}
                                                  {val}
                                                  {uploadedFiles.length !==
                                                      index + 1 && (
                                                      <>
                                                          {"\u00A0"}{" "}
                                                          <span
                                                              style={{
                                                                  color: "black",
                                                              }}
                                                          >
                                                              /
                                                          </span>
                                                      </>
                                                  )}
                                              </ViewFiles>
                                          </>
                                      );
                                  })
                                : ""}
                        </span>
                    </Grid>
                </>
            )}
            {!isView && (
                <>
                    <Grid item xs={12} md={12}>
                        <TitleLabel>
                            {label}
                            {maskText && isCustomer ? (
                                <Mask>{maskText}</Mask>
                            ) : (
                                ""
                            )}
                            {tempFile ? (
                                <>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <TempFileLink
                                        title="Click to Download the Template "
                                        href={tempFile}
                                        download={tempName}
                                        target="_blank"
                                        rel="noreferrer noopener"
                                    >
                                        <ExcelIcon />
                                        {label} Example Template
                                    </TempFileLink>
                                </>
                            ) : (
                                ""
                            )}
                        </TitleLabel>
                    </Grid>
                    <Grid item xs={xs} md={md}>
                        {isCustomer && (
                            <UploadContainer onDragEnter={handleDrag}>
                                <Input
                                    ref={inputRef}
                                    type="file"
                                    accept={accept}
                                    data-textid={id}
                                    multiple={multiple}
                                    onChange={handleFileChange}
                                />
                                <Label
                                    htmlFor="input-file-upload"
                                    aria-errormessage={error}
                                    style={dragActive ? dragStyle : {}}
                                >
                                    <div>
                                        <span>
                                            <AddDocumentIcon
                                                style={styleIcon}
                                            />
                                        </span>
                                        <UploadButton onClick={onButtonClick}>
                                            {message(
                                                "account.form.attachment.chooseFile"
                                            )}
                                        </UploadButton>
                                        {placeholder && (
                                            <span>{placeholder}</span>
                                        )}
                                    </div>
                                </Label>
                                {dragActive && (
                                    <DragContainer
                                        onDragEnter={handleDrag}
                                        onDragLeave={handleDrag}
                                        onDragOver={handleDrag}
                                        onDrop={handleDrop}
                                    ></DragContainer>
                                )}
                            </UploadContainer>
                        )}
                        {files && files.length ? (
                            <ProgressContainer>
                                <FileIcon />
                                {/* {progress < 100 ? (
              <ProgressBarBox>
                <LinearProgressWithLabel value={progress} />
              </ProgressBarBox>
            ) : ( */}

                                {`${files[0]?.name} ${
                                    files.length - 1
                                        ? `+ ${files.length - 1}`
                                        : ""
                                }`}
                                {/* )} */}

                                <CloseIcon
                                    height={25}
                                    width={25}
                                    fill={"#47474A"}
                                    cursor={"pointer"}
                                    onClick={handleCloseSelecetd}
                                />
                            </ProgressContainer>
                        ) : (
                            ""
                        )}
                        <Grid container spacing="12">
                            {uploadedFiles
                                ? accept && accept.indexOf("image") >= 0
                                    ? uploadedFiles.map((val, index) => {
                                          // eslint-disable-next-line react/jsx-key
                                          return (
                                              <Grid
                                                  key={index}
                                                  item
                                                  xs={4}
                                                  md={4}
                                                  style={{
                                                      position: "relative",
                                                      top: "-1px",
                                                  }}
                                              >
                                                  <Image
                                                      key={val}
                                                      src={`/api/file/img/${val}`}
                                                  />
                                                  <RemoveImage>
                                                      {" "}
                                                      <CloseIcon
                                                          height={15}
                                                          width={15}
                                                          fill={"#47474A"}
                                                          cursor={"pointer"}
                                                          style={{
                                                              borderRadius:
                                                                  "0.5rem",
                                                              backgroundColor:
                                                                  "white",
                                                          }}
                                                          onClick={() =>
                                                              removeUploadedFiles(
                                                                  val
                                                              )
                                                          }
                                                      />
                                                  </RemoveImage>
                                              </Grid>
                                          );
                                      })
                                    : uploadedFiles.map((val, index) => {
                                          // eslint-disable-next-line react/jsx-key
                                          return (
                                              <UploadedContainer key={index}>
                                                  <a
                                                      href={`/api/file/img/${val}`}
                                                      download={val}
                                                      target="_blank"
                                                      rel="noreferrer noopener"
                                                  >
                                                      <FileIcon />
                                                  </a>
                                                  {val}
                                                  <CloseIcon
                                                      height={25}
                                                      width={25}
                                                      fill={"#47474A"}
                                                      cursor={"pointer"}
                                                      onClick={() =>
                                                          removeUploadedFiles(
                                                              val
                                                          )
                                                      }
                                                  />
                                              </UploadedContainer>
                                          );
                                      })
                                : ""}
                        </Grid>
                        <Stack spacing={2}>
                            {success ? (
                                <Alert
                                    onClose={() => ""}
                                    severity="success"
                                    iconMapping={{
                                        success: <FileIcon />,
                                    }}
                                >
                                    Success
                                </Alert>
                            ) : (
                                ""
                            )}
                        </Stack>
                    </Grid>
                </>
            )}
        </>
    );
};

export default UploadInput;
function saveAs(url: string, name: any) {
    throw new Error("Function not implemented.");
}
